<template>
  <div>
    <v-dialog v-model="dialog" max-width="420" max-height="400" persistent>
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("delete") }} {{ roleName }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider />
        <v-row class="mt-4">
          <v-col class="pa-8"
            >Are you sure you want to delete the role {{ roleName }}?</v-col
          ></v-row
        >
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary text-none rounded-lg elevation-0"
            outlined
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            color="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            :disabled="!roleName.trim()"
            @click="deleteRole"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    roleName: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    deleteRole() {
      this.$emit("deleteRole", this.roleName);
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
